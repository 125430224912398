// @use "../globals.scss" as *;

.wrapper {
  column-count: 1;

  @media only screen and (min-width: 576px) {
    column-count: 2;
  }

  @media only screen and (min-width: 992px) {
    column-count: 3;
  }
}
