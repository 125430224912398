.titleLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.servers {
  text-align: center;

  .connectionBlock {
    font-size: 2em;
    margin: 0.4em;
  }

  .serverBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    row-gap: 1em;
    column-gap: 1em;

    .serverItem {
      width: 250px;
      color: #fff;

      .serverInfo {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        font-size: 0.7em;
      }
    }
  }
}

.chengelogs {
  margin-top: 2em;
  border-top: 1px solid grey;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;

  .changelog {
    width: 40%;
    text-align: left;
    padding: 2em 1em;
  }
}
