@use "../globals.scss" as *;
$heightHeader: 70px;
$heightFooter: 25px;

.wrapper {
  display: flex;
  width: 100%;

  .nav {
    flex-shrink: 1;
    width: 180px;
    min-width: 180px;
    background-color: var(--bs-gray-200);
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .logo {
      padding: 1em 1em;
      height: $heightHeader;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        text-decoration: none;
        color: var(--bs-dark);

        span {
          color: var(--bs-red);
        }
      }
    }

    .collapseBtn {
      position: fixed;
      bottom: $heightFooter;
      left: -3px;
    }

    nav {
      position: fixed;
      top: 0;
      left: 0;

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;

          a {
            display: block;
            border: 1px solid var(--bs-gray-200);
            text-decoration: none;
            color: var(--bs-dark);
            padding: 0.4em 0.6em;
            cursor: pointer;
            font-size: 0.8em;

            white-space: nowrap;
            overflow: hidden;
            text-align: left;

            &.active,
            &:hover {
              border: 1px solid var(--bs-gray-300);
              background-color: var(--bs-gray-100);
            }
          }
        }
      }
    }

    &.collapsed {
      max-width: 45px;
      min-width: 45px;
      nav {
        li {
          a {
            text-align: center;

            i {
              margin: 0 !important;
            }

            span {
              width: 0px;
              display: none;
            }
          }
        }
      }
    }
  }

  .contentWrap {
    flex-grow: 1;

    .header {
      background-color: var(--bs-dark);
      color: var(--bs-light);
      padding: 1em 1em;
      height: $heightHeader;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;

      .user {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        column-gap: 1em;

        .item {
          cursor: pointer;

          img {
            height: 1.8em;
            margin: 0 0.5em 0 0;
          }
        }
      }
    }

    .content {
      min-height: calc(100vh - $heightHeader - $heightFooter);
      padding: 1em 1em 1em 2em;
    }
  }
}

.footer {
  background-color: var(--bs-dark);
  color: var(--bs-light);
  padding: 5px;
  font-size: 10px;
  height: $heightFooter;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
