.table {
  thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;

    th {
      padding-bottom: 1em;
    }

    .tableHeaderComponentsWrap {
      padding-bottom: 0;
      border-bottom: none;

      .tableHeaderComponentsFlex {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        .tableHeaderComponents {
          width: 20%;
        }

        .tablePagination {
          width: 60%;
          ul {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }

        .tableHeaderActions {
          text-align: right;
          width: 20%;
        }
      }
    }
  }
}

.tableHeadItem {
  &.hasSearch {
    .tableHeadFilter {
      &:empty {
        height: 31px;
      }
    }
  }
}

.row {
  text-align: left;
}

.avatar {
  width: 30px;
  img {
    height: auto;
    width: auto;
  }
}

.actions {
  text-align: right;
}
