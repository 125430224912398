.button {
  display: inline-block;
  width: 191px;
  height: 46px;
  cursor: pointer;
  background-image: url("../assets/google-signin/btn_google_signin_light_normal_web.png");
  background-size: contain;

  &:hover,
  &:active {
    background-image: url("../assets/google-signin/btn_google_signin_light_pressed_web.png");
  }
}
